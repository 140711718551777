<template>
  <div>
    <ul class="tabs__header">
      <slot name="tab-titles"></slot>
    </ul>
    <div class="tab">
      <slot name="tab-content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  methods: {
    selectTab(index) {
      this.$emit('update:activeTab', index);
    }
  }
}
</script>


<style>
.tabs__header {
  display: block;
  list-style: none;
  margin: 0 0 0 20px;
  padding: 0;
}

.tabs__header > li {
  padding: 15px 30px;
  border-radius: 10px;
  margin: 0;
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
  background-color: #333;
  color: #aaa;
}

.tabs__header > li.tab__selected {
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  border-bottom: 8px solid transparent;
  background-color: #555;
  color: white;
}

.tab {
  display: block;
  color: #eee;
  padding: 20px;
  background-color: #555;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  height: 100%; /* Take full height of the parent */
  width: 100%; /* Take full width of the parent */
}
</style>
