import { createApp } from 'vue'
import { routes } from './router.js'
import App from './App.vue'
import {createWebHistory, createRouter} from "vue-router";
import { CAlert } from '@coreui/vue';
import '@coreui/coreui/dist/css/coreui.min.css';


const router = createRouter({
  history: createWebHistory(),
  routes,
})

createApp(App)
    .component('CAlert', CAlert)
    .use(router)
    .mount('#app')