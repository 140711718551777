<template>
  <div class="app-container">
    <div class="tabs-container">
      <Tabs :activeTab="activeTab" @update:activeTab="activeTab = $event">
        <template v-slot:tab-titles>
          <li v-for="(page, index) in labData.urls" :key="page.title" @click="activeTab = index"
              :class="{ 'tab__selected': index === activeTab }">
            {{ page.title }}
          </li>
        </template>
        <template v-slot:tab-content>
          <Tab v-for="(page, index) in processedUrls" :key="page.title" :title="page.title" :url="page.url"
               :isActive="index === activeTab"></Tab>
        </template>
      </Tabs>
    </div>
    <aside class="side-panel">
      <VueMarkdown :source="processedInstructions" />

      <div>
        <div v-if="showAlert" class="overlay">
          <div>
            <CAlert dismissible :color="alertType"  @close="() => { showAlert = false }">
              <CAlertHeading>{{alertHeading}}</CAlertHeading>
              <p>{{alertMessage}}</p>
            </CAlert>
          </div>
        </div>
      </div>
    </aside>
    <button class="validate-button" @click="validateAction(labName, realmName)">Validate</button>
  </div>
</template>


<script setup>
import {ref} from 'vue';
import {useRoute} from 'vue-router';
import Tabs from './components/Tabs.vue';
import Tab from './components/Tab.vue';
import VueMarkdown from 'vue-markdown-render';
import {CAlert} from '@coreui/vue';
import {CAlertHeading} from "@coreui/vue/dist/esm/components/alert";
import { computed } from 'vue';


// Reactive variables
const labData = ref({
  id: null,
  name: '',
  urls: [],
  instructions: ''
});
const activeTab = ref(0);

// alert

// alert
const showAlert = ref(false);
const alertHeading = ref('')
const alertMessage = ref('');
const alertType = ref('primary');


// Access route
const route = useRoute();

// Fetch lab data function
function fetchLabData(labName) {
  console.log('fetchLabData - Lab Name:', labName);
  fetch(`https://labs-check.keycloak.academy/lab/${labName}`)
      .then(response => response.json())
      .then(data => {
        labData.value = data;
        activeTab.value = 0; // Ensure the first tab is active
      })
      .catch(error => {
        console.error('Error fetching lab data :', error);
      });
}

// Validation action function
function validateAction(labName, realmName) {
  console.log("validate lab = ", realmName);

  fetch(`https://labs-check.keycloak.academy/check/${labName}?realm=${realmName}`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();  // Parse the JSON in the response
      })
      .then(data => {
        console.log("response data = ", data);
        alertMessage.value = data.message;
        if (data.status === 'success') {
          alertType.value = 'success';
          alertHeading.value = "Well done !"
        } else {
          alertType.value = 'warning';
          alertHeading.value = "Invalid setup !"
        }
      })
      .catch(error => {
        console.error('API call failed:', error);
          alertType.value = 'Unexpected Error !';
          alertHeading.value = "Something wrong happen while trying to verify your solution ..."
      });
  showAlert.value = true;
}


// Use route params to fetch data
const labName = route.params.labName;
const realmName = route.params.realmName;
console.log('Router.params =', route.params);
console.log('Lab name =', labName);
console.log('Realm =', realmName);

fetchLabData(labName);
// Compute the processed string
const processedInstructions = computed(() =>
  labData.value.instructions.replace(/\[\[realmname\]\]/g, realmName)
);

// Compute the processed URLs with the placeholder replaced
const processedUrls = computed(() =>
  labData.value.urls.map((page) => ({
    ...page,
    url: page.url.replace(/\[\[realmname\]\]/g, realmName)
  }))
);

</script>

<style scoped>
.app-container {
  display: flex;
  height: 100vh;
}

.tabs-container {
  width: 70%;
  height: 100%;
}

.side-panel {
  width: 30%;
  background-color: #555;
  color: white;
  padding: 20px;
  overflow-y: auto;
  position: relative;
}

.validate-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.validate-button:hover {
  background-color: #45a049;
}
</style>


<style>
/* Styles remain the same as previously defined */
.app-container {
  width: 100%;
  height: 100%; /* Ensure this takes full height */
  display: flex;
  background-color: #f9f9f9;
}

.tabs-container {
  width: 70%;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

.side-panel {
  width: 30%;
  background-color: #333;
  color: white;
  padding: 20px;
  overflow-y: auto;
  position: relative;
}

.validate-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.validate-button:hover {
  background-color: #45a049;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}
</style>
