<template>
  <main>
    <RouterView />
  </main>
</template>
<script setup lang="ts">
</script>

<style>
body {
  font-family: 'Roboto', sans-serif;
}
</style>