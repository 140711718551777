<template>
  <div v-show="isActive" class="tab-content">
    <div class="iframe-container">
      <iframe class="iframe-content" :src="url"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    title: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style>
.tab-content {
  width: 100%;
  height: 100%; /* Ensure this takes full height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.iframe-container {
  width: 100%;
  /* Aspect ratio of 16:9 */
  padding-bottom: 56.25%;
  height: 0px;
  position: relative;
}
.iframe-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
