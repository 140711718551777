import LabPage from '@/LabPage.vue';

export const routes = [
  {
    path: '/lab/:realmName/:labName',
    name: 'LabPage',
    component: LabPage,
  }
];

console.info(routes)